//
// Media
//

.youtube {
  height: 300px;
}

.video {
    max-height: 300px;
    padding: 0px;
    border-top: 1px solid #d1d1d1;

    video {
      height: 100%;
      width: 100%;
    }
}

.youtube iframe {
    pointer-events: all;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.video-title {
  cursor: pointer;
}
