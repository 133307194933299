/*

=========================================================
 * Impact Design System - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/impact-design-system
 * Copyright 1010 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/impact-design-system/blob/master/LICENSE.md)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,900;1,100;1,200;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
// Bootstrap functions
@import "bootstrap/functions";
@import "bootstrap/mixins";

// Argon functions
@import "custom/functions";
@import "custom/variables";
@import "bootstrap/variables";

// Argon mixins
@import "custom/mixins";

// Bootstrap components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/spinners";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Argon components
@import "custom/components";
@import "custom/utilities";
@import "custom/vendors";

// nucleo icons
@import "nucleo/nucleo.scss";

:root {
  --orange-predicta: #FF511A;
  --yellow-predicta: #FFB640;
  --lightblue-predicta: #407BFF;
  --blue-predicta: #222082;
  --green-predicta: #1CECBA;
  --lightgray-predicta: #C7C7C7;
  --gray-predicta: #979797;
}
.active-body {
  fill: var(--orange-predicta) !important;
}
.normal-body {
  fill: #dee1e0 !important;
}
.fade-out {
  opacity: 0;
  transition: 3s opacity;
}
.fade-in {
  opacity: 1;
}
.shadow-nutrition {
  padding: 16px;
  background-color: #fff;
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
}
.shadow-nutrition-1 {
  padding: 25px 12px;
}
.shadow-nutrition-2 {
  padding: 25px 12px;
}
.food-quality-component {
  padding: 0px 12px;
  display: flex;
  justify-content: center;
}
.rc-steps-item-finish {
  .rc-steps-item-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #cfcfcf !important;
  }
  .rc-steps-item-icon {
    background-color: #767676 !important;
    border-color: #767676 !important;
  }
  .rc-steps-item-icon > .rc-steps-icon {
    color: white !important;
    font-size: 16px !important;
    top: -1px !important;
  }
}
.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: #161616 !important;
}
.rc-steps {
  gap: 12px !important;
  justify-content: center;
  margin-top: 0px !important;
  cursor: none;
}
.rc-steps-item {
  flex: 0 !important;
}
.rc-steps-item-tail {
  display: none !important;
}
.rc-steps-item-icon {
  margin: 0px !important;
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
  border-radius: 24px !important;
}
.rc-steps-item-content {
  width: auto !important;
  display: none !important;
}
.steps-title {
  color: #161616;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 12px 0px;
}
.rc-steps-item-process {
  .rc-steps-item-icon {
    background-color: var(--orange-predicta) !important;
    border-color: var(--orange-predicta) !important;
    box-shadow: 0px 0px 10px rgba(255, 60, 27, 0.24) !important;
  }
  .rc-steps-item-title,
  .rc-steps-item-description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #161616 !important;
  }
  .rc-steps-item-description {
    width: 155px;
    margin-left: -25px;
  }
  .rc-steps-item-icon > .rc-steps-icon {
    color: white !important;
    font-size: 16px !important;
    top: -1px !important;
  }
}

.recomendation-p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #6B7575;
}
.recomendation-p-black {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  white-space: normal;
}

.rc-steps-item-wait {
  .rc-steps-item-description,
  .rc-steps-item-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #cfcfcf !important;
  }
  .rc-steps-item-description {
    width: 155px;
    margin-left: -25px;
  }
  .rc-steps-item-icon {
    background: #cfcfcf !important;
    border-color: #cfcfcf !important;
  }
  .rc-steps-item-icon > .rc-steps-icon {
    color: white !important;
    font-size: 16px !important;
    top: -1px !important;
  }
}
@media (max-width: 768px) {
  .rc-steps {
    gap: 8px !important;
  }
  .rc-steps-item-icon {
    width: 12px !important;
    height: 12px !important;
    border-radius: 12px !important;
  }
  .rc-steps-item-icon .rc-steps-icon {
    display: none;
  }
  .rc-steps-item-wait .rc-steps-item-icon {
    background: #fff !important;
    border: 2px solid #767676 !important;
  }
  .steps-title {
    font-size: 18px;
    font-weight: 600;
    margin: 12px 0px !important;
  }
}
.popver-style {
  background: #022282;
}
.inner-popver-style {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  color: #18223d;
  padding: 5px 0px;
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #022282 !important;
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #022282 !important;
}

.prev-button-position {
  position: absolute;
  bottom: -61px;
}
.prev-button {
  box-shadow: 0px 0px 20px rgba(24, 34, 61, 0.1);
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0.62rem 2.75rem;
  border-color: #18223d;
  background: #18223d;
  &:hover {
    color: #ffffff;
    border-color: #18223d;
    background: #18223d;
  }
}
.prev-button-question,
.next-button-question {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 28px;
  margin-top: 12px;
  background-color: transparent;
  border: none;
}
.prev-button-question {
  float: left;
  color: #161616;
}
.next-button-question {
  float: right;
  color: #0034ba;
}
.next-button-position {
  position: absolute;
  bottom: -81px;
  right: 0px;
}
.next-button {
  box-shadow: 0px 0px 20px rgba(24, 34, 61, 0.1);
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0.62rem 2.75rem;
  border-color: #30e8bd;
  background: #30e8bd;
  &:hover {
    color: #ffffff;
    border-color: #30e8bd;
    background: #30e8bd;
  }
}

.nope-button {
  box-shadow: 0px 0px 20px rgba(24, 34, 61, 0.1);
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0.62rem 2.75rem;
  border-color: #afbcce;
  background: #afbcce;

  &:hover {
    color: #ffffff;
    border-color: #afbcce;
    background: #afbcce;
    // #dbe4f0
  }
}
.login-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  & h1 {
    font-weight: normal;
    font-size: 72px;
    line-height: 88px;
    color: #062484;
  }
  & p {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #062484;
  }
}
.bienvenu-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  & h1 {
    font-weight: normal;
    font-size: 40px;
    line-height: 10px;
    color: #062484;
  }
  & p {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #062484;
  }
}
.cercle_icon {
  background: #d24634;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-align: center;
  line-height: 4px;
  vertical-align: middle;
  padding: 15px;
  font-size: 30px;
}
.cercle_black_icon {
  background: transparent;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-align: center;
  line-height: 4px;
  vertical-align: middle;
  padding: 15px;
  font-size: 30px;
}
.text-modification {
  width: 100%;
  border: 1px solid grey;
  border-radius: 9px;
}
.arrow_back {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.arrow_button {
  margin: 0% 1% 0% 1%;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 8px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
// .page-header {
//   display: flex;
//   align-items: center;
//   padding: 0px;
//   margin: 20px 0px 21px;
// }
// .h1-header {
//   font-size: 30px;
//   margin-top: 2%;
// }
.in-col {
  width: 8%;
  padding: 4%;
}
.padding-row {
  padding-top: 2%;
  padding-bottom: 2%;
}
.result-date {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #161616;
  margin-bottom: 12px;
}
.centred-p {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #0034ba;
  margin-bottom: 0px;
}
.result-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  row-gap: 16px;
  border-radius: 16px;
}
.result-graphe-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #161616;
  margin-bottom: 0px;
  padding-left: 16px;
}
@media (max-width: 768px) {
  .result-graphe-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
}
.centred-div {
  text-align: center;
}
.new-message {
  float: right;
  max-height: 50px;
}
.chat-div {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
}
.ni-fat-remove::before {
  margin: -13px;
}
.fixed-logo {
  display: flex;
  position: absolute;
  top: 16px;
  left: 19px;

  & img {
    width: 200px;
    height: 30px;
    margin-right: 5px;
  }
  & p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #0b2b5c;
  }
}
.Navbar-employee {
  padding: 60px 60px 6px 60px;
}
.Navbar-header-employee {
  margin: auto;
  // height: 20%;
}
.infos-btn {
  padding: 16px 21px 16px 18px;
  width: 100%;
  min-height: 60px;
  text-align: initial;
  background: transparent;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #161616 !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 8px !important;
  display: flex;
  justify-content: space-between;
  border: none;
}
button.infos-btn:hover {
  border-radius: 5px;
  border: 1px solid red;
}
.div-info {
  min-height: 75px;
  height: auto;
  width: 100%;
  border: 1px solid var(--lightgray-predicta);
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}
// Dashboard
.card-body-infos-perso {
  padding: 16px 16px !important;
}
.card-infos-perso {
  min-height: auto !important;
}
// Info Perso
.info-perso-modal,
.account-params-modal {
  max-width: 710px;
}
@media (max-width: 768px) {
  .info-perso-modal .modal-content,
  .account-params-modal .modal-content {
    top: 68px;
  }
}
.info-perso-modal-body {
  padding: 0px 16px 16px;
}

.form-info-perso,
.form-medical-record {
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
}
.form-medical-record {
  width: fit-content;
}
.container-info-perso,
.container-medical-record {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.info-perso-inputs,
.medical-record-inputs {
  display: flex;
  gap: 10px;
}
.gender-container {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 8px;
  flex-wrap: wrap;
  row-gap: 12px;
}
.gender-radios {
  display: flex;
}
@media (max-width: 768px) {
  .container-info-perso,
  .info-perso-inputs,
  .container-medical-record,
  .medical-record-inputs {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.div-info-perso,
.div-medical-record {
  height: 80px;
  width: 329px;
  border-radius: 8px;
  display: flex;
  column-gap: 8px;
  align-items: center;
  padding: 16px 12px;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
}
.left-div {
  color: #4059a1;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
.left-div-select {
  color: #4059a1;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  word-wrap: break-word;
}
.container-bttn {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.info-perso-cancel {
  background-color: #ffffff;
  box-shadow: none;
  border: none;
  color: #161616;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 0px !important;
  & :hover {
    box-shadow: none !important;
  }
}
.save-button {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #fff;
  background: #0034ba;
  border-radius: 8px !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  padding: 12px !important;
  width: 140px;
  margin: 0px;
}
.save-button:hover {
  box-shadow: none !important;
  background-color: #0034ba;
  border-color: #0034ba;
  color: #fff;
  transform: none !important;
}
// Update Info Perso
.account-params-modal-body {
  padding: 0px 16px 16px;
}
.div-update-info {
  min-height: 56px;
  height: auto;
  width: 100%;
  border: 1px solid var(--lightgray-predicta);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.div-update-mail {
  min-height: 60px;
  height: auto;
  width: 100%;
  border: 1px solid var(--lightgray-predicta);
  border-radius: 8px;
  padding: 16px 3%;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.left-p-update {
  color: #022282;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
  width: 148px;
}
.account-left-p-update {
  color: #022282;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
  width: 264px;
}
.info-perso-input {
  width: 148px;
  height: 48px;
}
.info-perso-input-select {
  width: 148px;
  height: 48px;
  margin-bottom: 0px !important;
}
.info-perso-input-select .css-1pahdxg-control {
  border-radius: 0.25rem !important;
  border-color: #0a48b3 !important ;
  box-shadow: none !important;
}
.info-perso-input-select .css-yk16xz-control {
  border-color: #dee2e6 !important ;
  border-radius: 0.25rem !important;
}
.info-perso-input-select .react-select__menu {
  // z-index: 99999;
  background: #ffffff !important;
  box-shadow: 0px 0px 45px rgba(219, 228, 240, 0.4) !important;
  border-radius: 11px !important;
  top: 64% !important;
  left: 3% !important;
  width: 100% !important;
  .react-select__menu-list {
    padding: 10px 0px !important;
    .react-select__option {
      // text-align: center;
      font-family: Poppins;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(22, 22, 22, 1);
      width: 100% !important;
      &.react-select__option--is-selected {
        background: rgba(227, 236, 255, 1);
        border-radius: 6px !important;
      }
      &.react-select__option--is-focused {
        background: rgba(227, 236, 255, 1);
        border-radius: 6px !important;
        &.react-select__option--is-selected {
          background: rgba(227, 236, 255, 1);
        }
      }
    }
  }
}
.account-input {
  flex-grow: 1;
  height: 48px;
}
@media (max-width: 410px) {
  .div-info-perso,
  .div-medical-record {
    width: 311px;
  }
  .left-p-update {
    width: 139px;
  }
  .account-left-p-update {
    min-width: 120px;
  }
  .info-perso-input,
  .info-perso-input-select {
    width: 139px;
  }
  .info-perso-modal .modal-content,
  .account-params-modal .modal-content {
    max-width: 343px;
    margin: auto;
  }
}
.field-pwd-wrapper {
  flex-grow: 1;
}
.right-div-update {
  color: #161616;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.container-update-pwd {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.div-update-pwd {
  height: 80px;
  border: 1px solid var(--lightgray-predicta);
  border-radius: 8px;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

// DeleteAcount
.delete-account-modal {
  padding: 0px 16px 0px;
}
.delete-account-footer-modal {
  padding-top: 45px;
  padding-bottom: 40px;
}
.delete-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-pragraphe {
  font-family: Poppins;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #161616;
  margin-bottom: 10px;
}
.delete-warning {
  font-family: Poppins;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #D02A2A;
}
.delete-account {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background-color: #022282 !important;
  border-color: #022282 !important;
  width: 107px;
}
.delete-account :hover {
  background-color: #022282 !important;
  border-color: #022282 !important;
}
.input-password {
  border: none;
}
.Alert-Questionnaire {
  border: 2px solid #C34F00;
  background-color: #fffbe6;
  padding: 13px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // max-width: 1140px;
  margin: 10px auto 0px;
}
.alert-infos {
  display: flex;
  align-items: center;
  column-gap: 7px;
}
.alert-status {
  display: flex;
  align-items: center;
  column-gap: 12px;
  min-width: 120px;
}
.alert-status > p {
  margin-bottom: 0px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #C34F00;
}
.alert-paragraphe {
  margin-bottom: 0px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #5c5c5c;
}
.alert-buttons {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.questionnaire-btn {
  border-radius: 4px;
  border: none;
  width: 240px;
  background: #C34F00;
  color: #ffffff !important ;
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 8px;
  text-align: center;
}
a.questionnaire-btn:hover {
  color: #ffffff !important ;
}
.mobile-close-icon-container {
  display: none;
}
@media (max-width: 768px) {
  .Alert-Questionnaire {
    flex-direction: column;
    row-gap: 10px;
  }
  .alert-infos {
    flex-direction: column;
    row-gap: 4px;
  }
  .alert-status {
    width: 100%;
  }
  .alert-paragraphe {
    font-size: 12px;
    line-height: 18px;
  }
  .questionnaire-btn {
    width: 230px;
  }
  .web-close-icon-container {
    display: none;
  }
  .mobile-close-icon-container {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
}
@media (max-width: 400px) {
  .Alert-Questionnaire {
    padding: 16px 56px;
  }
  .alert-infos {
    align-items: flex-start;
  }
  .alert-status {
    margin-left: -26px;
  }
  .alert-buttons,
  .questionnaire-btn {
    width: 100%;
  }
  .mobile-close-icon-container {
    margin-right: -59px;
  }
}
.left-p {
  float: left;
  color: #4059a1;
  font-weight: 500;
  font-size: 15px;
}
.h2-modal {
  text-align: center;
  color: #022282;
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px !important;
}

.arrow {
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.right {
  float: right;
  margin-top: 2%;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.right:active {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.card_width {
  height: 300px;
  max-width: 1000px;
}
.has-danger.form-control.input-style {
  background: #f9faff !important;
  border: 1px solid red !important;
}
.form-control.input-style {
  background: #f9faff !important;
  border-radius: 12px;
  border-color: transparent !important;
  height: 48px;
  &::placeholder {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 20px;
    color: #8898aa;
  }
}

// .react-select__menu {
//   z-index: 9999;
// }
.react-select__control.react-select__control--is-focused {
  outline: none !important;
}
.react-select__control {
  background-color: transparent !important;
  border-radius: 12px !important;
  border-color: gray !important;
  height: 48px;
  box-shadow: 0px 0px 10px rgba(219, 228, 240, 0.27);
}
.react-select__placeholder {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 20px;
  color: #8898aa !important;
}
.react-select__indicators {
  .react-select__indicator-separator {
    width: 0 !important;
  }
  .react-select__indicator.react-select__dropdown-indicator {
    & svg {
      color: #0b2b5c;
    }
  }
}
.react-select__menu {
  // z-index: 99999;
  background: #ffffff !important;
  box-shadow: 0px 0px 45px rgba(219, 228, 240, 0.4) !important;
  border-radius: 11px !important;
  top: 64% !important;
  left: 3% !important;
  width: 94% !important;
  .react-select__menu-list {
    padding: 10px 10px !important;
    .react-select__option {
      text-align: center;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 26px;
      color: #18223d;
      width: 90% !important;
      &.react-select__option--is-selected {
        background: rgba(170, 188, 201, 0.5);
        border-radius: 6px !important;
      }
      &.react-select__option--is-focused {
        background: rgba(170, 188, 201, 0.1);
        border-radius: 6px !important;
        &.react-select__option--is-selected {
          background: rgba(170, 188, 201, 0.5);
        }
      }
    }
  }
}
.control-label {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;

  color: #18223d;
}
.custom-control.custom-control-alternative.custom-radio {
  .text-muted {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000 !important;
  }
  .custom-control-label {
    &::before {
      border: 1px solid #18223d;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: transparent !important;
    border: 4px solid #18223d;
  }
}

.navbarCustomStyle {
  margin-bottom: 80px;
}
.card-shadow-style {
  background: #ffffff;
  box-shadow: 0px 0px 45px rgba(219, 228, 240, 0.21);
  border-radius: 16px;
  position: relative;
  height: auto;
  min-height: 300px;
}
.card-shadow-style-specific-video {
  background: #ffffff;
  box-shadow: 0px 0px 45px rgba(219, 228, 240, 0.21);
  border-radius: 24px;
  position: relative;
  height: auto;
  min-height: 150px;
  margin: 0px !important;
}
.medical-title {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  color: #18223d;
  margin-bottom: 20px;
  & h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  & p {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
}
.transition-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #022282;
  text-align: center;
}
.rps-question-state {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5c5c5c;
  text-align: center;
}
.continue-text {
  text-align: center;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  & p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #161616;
    margin: 0px;
  }
  & .nextButton {
    background-color: #0034ba !important;
    color: #fff !important;
    font-family: Poppins !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    border: 8px !important;
    padding: 12px !important;
    width: 100% !important;
  }
}
.ask-parent {
  height: 200px;
}

.login-button {
  background: #062484 !important;
  border-color: #062484 !important ;
  box-shadow: 0px 0px 20px rgba(24, 34, 61, 0.1);
  & :hover {
    border-color: #062484 !important ;
    background: #062484 !important;
  }
}
.sections-head {
  position: absolute;
  top: -91px;
  left: -19px;
  & p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #547894;
  }
}
.question-answer {
  text-align: center;
  .question {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #161616;
  }
  .ansewer {
    width: 100%;
    .answer-item {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      background: #f1f8ff;
      border-radius: 12px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #5c5c5c;
      transition: all ease 0.3s;
      height: 48px;
      box-shadow: none !important;
      margin-top: 20px !important;
      border: none !important;
      cursor: pointer;
      &:hover {
        background: #547893;
        color: white;
      }
      &:focus {
        background: #547893;
        color: white;
      }
    }
    .answered {
      background: #547893;
      color: white;
    }
  }
}

.second-page-style {
  margin-top: -50px !important;
}
.seven-page-style {
  background: transparent !important;
  box-shadow: transparent;
}

.fileUploadWrap {
  // padding-top: 10px;
  margin-left: 10px;
  position: relative;
}
.upload_img {
  position: absolute;
  cursor: pointer;
  /*   below means you can click through the image onto the invisible input */
  pointer-events: none;
  width: 50px;
  top: 50%;
  transform: translatey(-50%);
}
.fileName {
  // position: absolute;
  // left: 40px;
  // top: 5px;
  // cursor: pointer !important;
  // pointer-events: none;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0034ba;
  text-decoration: underline;
  margin: 0px;
}
.file-upload-container {
  display: flex;
  cursor: pointer;
}
input[type="file"] {
  margin-left: -25px;
  opacity: 0;
}
@media (max-width: 0px) {
  .Navbar-header-employee {
    display: none;
  }
  .navbar-expand-md {
    width: 20%;
  }
}

@media (max-width: 1199.98px) {
  .container-fluid {
    margin-left: 0 !important;
  }

  .nav-link-text {
    display: none;
  }
  // .sidenav-header
  .Navbar-header-employee {
    display: none;
  }
  .navbar-expand-md {
    width: 5%;
  }
  .g-sidenav-pinned .sidenav {
    max-width: 72px !important;
  }
  .Navbar-header-employee {
    display: none;
  }
  .navbar-collapse {
    float: none !important;
  }
  .sidenav.fixed-left + .main-content {
    margin-left: 62px !important;
  }
}
@media (max-width: 768px) {
  .line {
    display: none;
  }

  .point {
    padding-left: 30%;
  }
}
// @media (min-width: 768px) {
//   .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
//     padding: 0.5rem 0.5rem;
//   }
//   .navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
//     padding: 0.5rem 0.5rem;
//     margin: auto;
//   }
// }

.last_sentence {
  margin: 15% 20%;
}
.card_you_know {
  border-radius: 10%;
  width: 60%;
  justify-content: center;
  margin: auto;
}
.perpendicular_lines {
  height: 20px;
  width: 100px;
  display: inline-block;
  border-left: 5px solid;
  border-bottom: 5px solid;
  position: relative;
}
.perpendicular_lines_top {
  height: 100px;
  width: 10px;
  display: inline-block;
  border-right: 5px solid;
  border-top: 5px solid;
  position: relative;
}
.roof {
  height: 55px;
  width: 15px;
  border-right: 5px solid black;
  border-bottom: 5px solid black;
  transform: rotate(45deg) translate(-2.5em);
  position: absolute;
}

.navbar-nav.bottom-nav > .nav-item > .nav-link {
  background: #f1fffc !important;
  color: var(--green-predicta) !important;
}
.navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
  padding: 8px 0px 8px 24px !important;
  column-gap: 10px;
}
.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active {
  background: #fff8f5 !important;
  border-radius: 8px !important;
  padding: 8px 0px 8px 24px !important;
  column-gap: 10px;
  margin: 0px !important;
  color: #022282 !important;
}
.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active.nutrition,
.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active.media {
  background: #fff !important;
  border-radius: 8px !important;
  padding: 8px 0px 8px 24px !important;
  column-gap: 10px;
  margin: 0px !important;
}
.under-navbar {
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
}
.g-sidenav-hidden .sidenav {
  max-width: 72px !important;
}
.navbar-nav > .nav-item > .nav-link.active svg {
  filter: unset;
}

.navbar-nav > .nav-item > .nav-link.active .nav-link-text {
  color: #022282;
}

.btn-custom-orange {
  background: #ff6600;
  border-radius: 8px;
  border-color: #f60;
  color: #fff;
  box-shadow: 0px 0px 45px rgba(219, 228, 240, 0.4);
}

.btn-custom-blue {
  background: #022282;
  box-shadow: 0px 0px 45px rgba(219, 228, 240, 0.4);
  border-radius: 8px;
  color: white;
}

.sidenav.navbar.navbar-vertical.fixed-left.navbar-expand-xs.navbar-light.bg-white.navbar-expand-md {
  border-color: white !important;
  box-shadow: unset !important;
  overflow-y: scroll;
}

.sidenav::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
.sidenav {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.card-title {
  margin-bottom: 1.25rem;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
}

.header-body .TitlePage {
  font-weight: 600;
  font-size: 43.3726px;
  line-height: 65px;
  font-family: Poppins;
  color: #022282;
}
.model {
  overflow-y: auto;
  max-height: 80vh;
  overflow-x: hidden;
  margin: 10px 60px 0px 60px;
  padding-top: inherit;
}
.modelContent {
  width: 800px;
}

.model::-webkit-scrollbar {
  width: 0.3em;
  display: inherit;
}
.model::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #999999;
  height: 300px;
}

.modelClose {
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--orange-predicta);
  border: none;
}
.modelClose:focus {
  outline: 0;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
  }
}

.rhap_progress-indicator,
.rhap_progress-filled,
.rhap_download-progress {
  background: #ff5019 !important;
}
.rhap_time {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px;

  color: #022282 !important;
}

.authentication-container {
  margin: 0px !important;
  height: inherit;
  position: relative;
}

.responsive-bottom-nav-bar {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 11px 3%;
}
.login-container,
.register-container {
  padding: 0px 5% !important;
}
.login-card {
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 28px;
  position: relative;
  top: 50%;
  transform: translate(0px, -50%);
  margin-bottom: 0px !important;
}
.login-header {
  font-family: "Poppins";
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px !important;
  & h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: var(--orange-predicta);
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #767676;
    text-align: center;
  }
}
.register-header {
  font-family: "Poppins";
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px 0px 24px !important;
  & h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: var(--orange-predicta);
    margin-bottom: 24px !important;
  }
  & p {
    margin: 0px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #767676;
    text-align: center;
  }
}
.form-elts {
  margin: 10px 0px;
}
.login-bttn {
  background: #022282 !important;
  border-color: #022282 !important ;
  box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.06) !important;
  border-radius: 8px !important;
  display: inline !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 0px !important;
  color: #ffffff !important;
  opacity: 1 !important;
  & :hover {
    color: #ffffff !important;
  }
}
.forget-psswd {
  display: flex;
  text-align: justify;
  color: rgba(0, 52, 186, 1) !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  font-family: Poppins;
}
.has-account {
  display: flex;
  text-align: center;
  color: rgba(0, 0, 0, 1) !important;
  margin-top: 0px !important;
  margin-bottom: 16px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  font-family: Poppins;
  gap: 10px;
  flex-wrap: wrap;
}
@media (max-width: 490px) {
  .modal-component-logo-predicta {
    width: 100%;
  }
}

.label-container {
  color: rgba(117, 117, 117, 1) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  font-family: Poppins;
}
.label-container p {
  margin-bottom: 0px !important;
  display: inline !important;
}
.logo-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 0 !important;
  padding-left: 6% !important;
}
.logo {
  width: inherit;
  height: inherit;
}
@media (max-width: 768px) {
  .logo-container {
    display: none;
  }
}
@media (max-width: 766px) {
  .login-container,
  .register-container {
    position: initial;
    // padding: 0px 16px !important;
  }
  .login-card {
    margin-top: 20px;
    top: 0%;
    transform: translate(0px, 0px);
  }
}
@media (max-width: 520px) {
  .login-container,
  .register-container {
    padding: 0px 16px !important;
  }
}
//NUTRITION
.nutrition-content {
  border-radius: 0px !important;
  box-shadow: 0px 0px 0px !important;
  background: #f8f9fe !important;
  background-color: #f8f9fe !important;
  border: 0px !important;
  padding: 0px !important;
  position: relative;
  height: auto;
  min-height: 300px;
}
.cancel-bttn {
  border: none;
  box-shadow: 0px 0px 0px;
  color: #022282;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  &:hover {
    color: #022282;
    border: none;
    box-shadow: 0px 0px 0px;
  }
}

// NUTRITION RESULTS SCSS
.food-quality-result {
  margin: 20px auto 0px;
  padding: 0px;
}
.profil,
.recommandations,
.food,
.infos {
  margin-top: 20px;
  // margin-left: 20px;
}

.title {
  color: #161616;
  font-family: Poppins;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
}
.details-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 12px;
}
.recommandations-details-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
}
.food-details-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
}
@media (max-width: 768px) {
  .details-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 12px;
  }
  .recommandations-details-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.profil-details {
  width: 240px;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
@media (min-width: 510px) {
  .profil-details {
    width: 240px;
  }
}
@media (min-width: 992px) {
  .profil-details {
    width: 230px;
  }
}
@media (min-width: 1240px) {
  .profil-details {
    width: 240px;
  }
}
.detail-title {
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 21px;
  color: #022282;
}
.v-line {
  border-left: 1px solid #161616;
  height: 27px;
}
.detail-info {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  color: #161616;
}
.neglect {
  padding: 0px 10px 0px 0px;
}
.exceed {
  padding: 0px 0px 0px 10px;
}
.energy {
  padding: 0px 10px;
}
.not-to-neglect-title,
.not-to-exceed-title {
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  line-height: 21px;
  text-align: center;
}
.not-to-neglect-title {
  color: var(--green-predicta);
}
.not-to-exceed-title {
  color: var(--orange-predicta);
}
.neglect-exceed-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.neglect-detail-info {
  width: 32%;
  height: 186px;
  padding-top: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.exceed-detail-info {
  width: 32%;
  height: 186px;
  padding-top: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.energy-detail-info {
  height: 186px;
  margin-top: 28px;
  padding-top: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 768px) {
  .energy-detail-info {
    margin-top: 15px;
  }
}
.neglect-exceed-food-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #161616;
  margin-top: 0.5rem;
  text-align: center;
}
.not-to-exceed-food-dosage,
.not-to-neglect-food-dosage,
.energy-food-dosage {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
}
.not-to-neglect-food-dosage {
  color: var(--green-predicta);
}
.not-to-exceed-food-dosage {
  color: var(--orange-predicta);
}
.energy-food-dosage {
  color: #5c5c5c;
}
.details-block-0 {
  padding: 0px 10px 0px 0px;
}
.details-block-1 {
  padding: 0px 10px;
}
.details-block-2 {
  padding: 0px 0px 0px 10px;
}
@media (max-width: 768px) {
  .details-block-0,
  .details-block-1,
  .details-block-2 {
    padding: 0px;
  }
}
.subtitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #022282;
  text-align: center;
}
.food-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  padding: 16px;
  background: #ffffff;
  width: max-content;
  margin: auto;
}
.food-alert > p {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #6B7575;
  text-align: center;
  max-width: 488px;
  margin-bottom: 0px;
}
.food-alert > a {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff !important;
  background: #0034ba !important;
  text-align: center;
  width: 100%;
  margin: 0px !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  border-radius: 8px !important;
  padding: 16px 8px !important;
}
@media (max-width: 768px) {
  .food-quality-container-msg-header {
    text-align: center;
    display: block !important;
  }
  .food-quality-title {
    text-align: center;
  }
}
@media (max-width: 520px) {
  .food-alert {
    width: auto;
  }
}
.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
}
.food-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 16px;
  background-color: #fff;
  width: 100%;
  padding: 16px;
  height: 88px;
}
.no-food-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 0px;
  color: #5c5c5c;
  text-align: center;
}
.food-name,
.pourcentage {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 0px;
}
.food-name {
  color: #161616;
  text-align: center;
  width: 96px;
}
.pourcentage {
  color: #000;
}
.red-cercle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #d91e1e;
}
.green-cercle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #00aa25;
}
.yelow-cercle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ffeb00;
}
.text-info {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: var(--lightgray-predicta) !important;
}
// SwitchFoodQuality
.food-quality-container {
  background-color: #ffffff;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 16px;
  column-gap: 60px;
  row-gap: 16px;
}
.food-quality-container > svg {
  width: 356px;
}
.food-quality-container-msg {
  background-color: #ffffff;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 16px;
  padding: 12px 16px;
  border: 3px solid var(--orange-predicta);
}
.food-quality-container-msg-header {
  display: initial;
}
.food-quality-container-msg > svg {
  width: 24px;
  height: 24px;
}
.food-quality-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #161616;
  display: inline-block;
  margin-bottom: 0px !important;
  margin-top: 8px;
}
.food-quantity-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
  margin-bottom: 0px !important;
}
.food-quality-note {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--orange-predicta);
  margin-left: 10px;
  margin-right: 16px;
}
.food-quality-title b {
  color: var(--orange-predicta);
}
.nutrition-modal-pragraphe {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #161616;
  width: 110%;
}

// Tutos Explicatifs
.tooltip-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1070;
  background-color: rgba(0, 0, 0, 0.3);
}
.modal {
  z-index: 1080;
  background-color: rgba(22, 22, 22, 0.4);
}
.tuto-modal-body {
  display: flex;
  flex-direction: column;
  padding-top: 16px !important;
}
.tuto-modal-title {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--orange-predicta);
  margin-bottom: 14px;
}
.modal-paragraphe {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  // text-align: justify;
  color: #5c5c5c;
  margin-bottom: 10px;
}
.tuto-modal-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.signature {
  margin: 0px 0px 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #000000;
}
.modal-container-bttn {
  width: -webkit-fill-available !important;
  width: -moz-available;         
  width: fill-available;
}
.hide-msg-checkbox {
  width: 50%;
}
.modal-container-bttn .first-connection-buttons,
.modal-container-bttn .no-first-connection-buttons {
  column-gap: 20px;
  row-gap: 10px;
  display: flex;
  margin-top: 16px;
}
.modal-bttn {
  box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.06);
  background-color: var(--orange-predicta);
  color: #ffffff;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  width: 50%;
  &:hover {
    background-color: var(--orange-predicta);
    color: #ffffff;
  }
}
.modal-bttn-skip {
  box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.06);
  background-color: #f0f0f0;
  color: #5c5c5c;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  width: 50%;
  &:hover {
    background-color: #f0f0f0;
    color: #5c5c5c;
  }
}
@media (max-width: 520px) {
  .modal-container-bttn .first-connection-buttons {
    flex-direction: column-reverse;
  }
  .modal-container-bttn .no-first-connection-buttons {
    flex-direction: column;
  }
  .modal-bttn,
  .modal-bttn-skip,
  .hide-msg-checkbox {
    width: 100%;
  }
}
.tuto-mobile-side-bar {
  display: none;
}
.first-tooltip .tooltip {
  left: 280px !important;
  top: 90px !important;
  opacity: 1 !important;
}
.second-tooltip .tooltip {
  left: 280px !important;
  top: 100px !important;
  opacity: 1 !important;
}
.third-tooltip .tooltip {
  left: 280px !important;
  top: 90px !important;
  opacity: 1 !important;
}
.fourth-tooltip .tooltip {
  left: 280px !important;
  top: 115px !important;
  opacity: 1 !important;
}
.fifth-tooltip .tooltip {
  left: 280px !important;
  top: 103px !important;
  opacity: 1 !important;
}
.sixth-tooltip .tooltip {
  left: 280px !important;
  top: 105px !important;
  opacity: 1 !important;
}
.seventh-tooltip .tooltip {
  left: 280px !important;
  top: -70px !important;
  opacity: 1 !important;
}
@media (max-width: 1199.98px) {
  .first-tooltip .tooltip {
    top: 55px !important;
    left: 11% !important;
  }
  .second-tooltip .tooltip {
    top: 90px !important;
    left: 11% !important;
  }
  .third-tooltip .tooltip {
    top: 155px !important;
    left: 11% !important;
  }
  .fourth-tooltip .tooltip {
    top: 210px !important;
    left: 11% !important;
  }
  .fifth-tooltip .tooltip {
    top: 265px !important;
    left: 11% !important;
  }
  .sixth-tooltip .tooltip {
    top: 315px !important;
    left: 11% !important;
  }
  .seventh-tooltip .tooltip {
    top: 200px !important;
    left: 11% !important;
  }
}
.tooltip-content {
  border-radius: 0px 28px 28px;
  background: linear-gradient(90deg, #ffffff -1.29%, #ffffff 5.07%);
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  max-width: 343px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #575757;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  padding: 16px;
  gap: 20px;
}
.last-tooltip-content {
  border-radius: 28px 28px 28px 0px;
  background: linear-gradient(90deg, #ffffff -1.29%, #ffffff 5.07%);
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  max-width: 343px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #575757;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  padding: 16px;
  gap: 20px;
}
.tooltip-arrow {
  top: 0px !important;
  padding: 0px !important;
}
.tooltip-arrow::before {
  border-width: 0px 27px 20px 0 !important;
  border-right-color: #fff !important;
}
.last-tooltip-arrow {
  bottom: 0px !important;
  top: 150px !important;
  padding: 0px !important;
}
.last-tooltip-arrow::before {
  border-width: 20px 27px 0px 0px !important;
  border-right-color: #fff !important;
}
.tooltip-container-bttn {
  display: flex;
  gap: 20px;
  width: 100%;
  // width: -webkit-fill-available !important;
  // justify-content: flex-end;
}
.tooltip-bttn {
  box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.06);
  background-color: var(--orange-predicta);
  color: #ffffff;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  width: 50%;
  padding: 12px;
  &:hover {
    background-color: var(--orange-predicta);
    color: #ffffff;
  }
}
.tooltip-bttn-skip {
  box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.06);
  background-color: #f0f0f0;
  color: #5c5c5c;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  width: 50%;
  margin: 0px !important;
  padding: 0px 12px;
  &:hover {
    background-color: #f0f0f0;
    color: #5c5c5c;
  }
}

.active-element {
  background: #ffffff !important;
  border-radius: 8px !important;
  margin-left: 5px;
  padding-right: 8px !important;
}
@media (max-width: 768px) {
  .tuto-web-side-bar {
    display: none !important;
  }
  .tuto-mobile-side-bar {
    display: block;
    z-index: 1050;
    background: #adaeb1;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    position: fixed;
    top: 48px;
  }
  .tuto-mobile-side-bar .navbar-nav .nav-item .nav-link {
    display: flex;
    align-items: center;
    white-space: nowrap;
    grid-column-gap: 10px;
    column-gap: 10px;
    padding: 8px 0px 8px 24px !important;
  }
  .mobile-active-element {
    background: #ffffff !important;
    border-radius: 4px !important;
  }
  .tooltip-content,
  .last-tooltip-content {
    border-radius: 28px !important;
  }
  .first-tooltip .tooltip {
    top: 110px !important;
    left: 10% !important;
    transform: none !important;
    position: fixed !important;
  }
  .second-tooltip .tooltip {
    top: 155px !important;
    left: 10% !important;
    transform: none !important;
    position: fixed !important;
  }
  .third-tooltip .tooltip {
    top: 200px !important;
    left: 10% !important;
    transform: none !important;
    position: fixed !important;
  }
  .fourth-tooltip .tooltip {
    top: 240px !important;
    left: 10% !important;
    transform: none !important;
    position: fixed !important;
  }
  .fifth-tooltip .tooltip {
    top: 285px !important;
    left: 10% !important;
    transform: none !important;
    position: fixed !important;
  }
  .sixth-tooltip .tooltip {
    top: 330px !important;
    left: 10% !important;
    transform: none !important;
    position: fixed !important;
  }
  .seventh-tooltip .tooltip {
    top: 370px !important;
    left: 10% !important;
    transform: none !important;
    position: fixed !important;
  }
  .tooltip-arrow::before,
  .last-tooltip-arrow::before {
    transform: rotate(-45deg);
    border-width: 0px 27px 27px 0 !important;
  }
  .tooltip-arrow,
  .last-tooltip-arrow {
    left: 172px !important;
    top: -6px !important;
  }
}
@media (max-width: 520px) {
  .first-tooltip .tooltip,
  .second-tooltip .tooltip,
  .third-tooltip .tooltip,
  .fourth-tooltip .tooltip,
  .fifth-tooltip .tooltip,
  .sixth-tooltip .tooltip,
  .seventh-tooltip .tooltip {
    left: 4.5% !important;
  }
}
// Nutrition Modal
.unanswered-ques {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  background: rgba(255, 255, 255, 1);
}
// Nutrition Modal
.unanswered-nutrition {
  z-index: 99;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}
.blur {
  filter: blur(6px);
}
// .unanswered-nutrition::before {
//   content: "";
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   bottom: -20px;
//   right: 0px;
//   filter: blur(3px);
// }
.nutrition-modal-content {
  padding: 10% 25% 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.media-sub-nav-icon {
  transform: scale(1.8);
  margin-left: 5px;
}

.react-player-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  padding: 1em 0;
  .react-player {
    border-radius: 1em;
  }
}
.react-player-specific-video {
  border-radius: 8px;
  overflow: hidden;
}
.react-player-specific-video video {
  border-radius: 8px;
}
.react-player iframe {
  pointer-events: auto;
}
.video-description {
  color: var(--lightgray-predicta);
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 21px;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-align: justify;
}

// Video Panel
.panel-wrapper {
  border: 2px dashed #00c8be;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 16px 2.5%;
  margin-bottom: 40px;
  background-color: #fff;
  gap: 16px;
}
.panel-infos {
  margin-left: 10px;
}
.panel-title {
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  font-style: normal;
  line-height: 27px;
  color: #00c8be;
  margin-bottom: 0px;
}
.panel-description {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  color: #161616;
  margin-bottom: 0px;
}
.icons-wrapper {
  display: grid;
}
@media (max-width: 768px) {
  .panel-wrapper {
    flex-direction: column;
  }
  .panel-infos {
    margin-top: 10px;
  }
  .icons-wrapper {
    display: block;
  }
}
// page accueil manager
.main-home-container {
  display: flex;
  flex-direction: column;
  row-gap: 22px;
}
.data-items {
  display: flex;
  align-items: center;
  column-gap: 30px;
  padding: 20px 0px;
}
.average-age-item {
  margin-bottom: 0px !important;
  padding: 50px 15px 0px 50px !important;
  width: 32%;
  align-self: normal;
}
.average-age-item h6 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
  width: 160px;
  margin-bottom: 27px;
}
.average-age-item-infos {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.average-age-item-infos h3 {
  font-family: Roboto;
  font-weight: 500;
  font-size: 96px;
  line-height: 113px;
  color: #022282;
  margin-bottom: 20px;
}
.average-age-item-infos h3 span {
  font-family: Roboto;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #ff6600;
  display: block;
}
.average-age-item-icon {
  width: 141;
  display: flex;
  justify-content: flex-end;
}
.first-pie-chart-item {
  margin-bottom: 0px !important;
  width: 32%;
  height: 490px;
  padding: 30px 0px !important;
}
.first-pie-chart-item h6 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
  margin-bottom: 76px;
  padding-left: 33px;
}
.second-pie-chart-item {
  margin-bottom: 0px !important;
  width: 32%;
  padding: 30px 0px !important;
  height: 394;
}
.second-pie-chart-item h6 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
  margin-bottom: 36px;
  padding-left: 33px;
}
.cell-double-translate {
  transform: translate(1px, 1px) !important;
}
.cell-inverse-double-translate {
  transform: translate(-1px, 1px) !important;
}
.cell-one-translate {
  transform: translate(0px, 2px) !important;
}
.recharts-default-legend {
  display: flex;
  justify-content: space-between;
}
.recharts-legend-item {
  font-size: 14px;
}

.average-work-sleep-item {
  margin-bottom: 0px !important;
  width: 32%;
  padding: 30px 23px 42px 20px !important;
  align-self: normal;
}
.average-work-sleep-item h6 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
  width: 165px;
  margin-bottom: 27px;
}
.average-work-sleep-value {
  font-family: Roboto;
  font-weight: 500;
  font-size: 96px;
  line-height: 113px;
  color: #022282;
  width: min-content;
  margin-bottom: 30px;
}
.average-work-sleep-value span {
  font-family: Roboto;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #ff6600;
  display: block;
  text-align: end;
}
.bar-chart-item {
  margin-bottom: 0px !important;
  padding: 30px 0px 30px 30px !important;
  flex-grow: 1;
  align-self: normal;
}
.bar-chart-item h6 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
  width: 230px;
  margin-bottom: 35px;
}
.clickable-icon {
  cursor: pointer;
}

.nutrition-item {
  position: relative;
  p {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
  }
}
.cliked-nutrition-item {
  position: relative;
  p {
    color: #0cd28a;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
  }
}
.used-nutrition-item {
  opacity: 0.2;
  position: relative;
  pointer-events: none;
  p {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
  }
}
.nutrition-item .delete-nutrition-button {
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: white !important;
  height: 1rem !important;
  width: 1rem !important;
  border-radius: 50% !important;
  font-size: 1rem !important;
  display: block !important;
  transition: all 0.15s ease !important;
  line-height: 13px !important;
  display: none !important;
}

.nutrition-item:hover .delete-nutrition-button {
  display: block !important;
}

.selected-survey-button {
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 8px;
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  padding: 11px 16px;
  background: var(--orange-predicta);
  border-color: var(--orange-predicta);
  margin-right: inherit !important;
  width: 310px;
  &:hover {
    color: #ffffff;
    background: var(--orange-predicta);
    border-color: var(--orange-predicta);
  }
}

.unselected-survey-button {
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border-radius: 8px;
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: var(--orange-predicta);
  text-align: center;
  padding: 10px 16px;
  background: #ffffff;
  border: 2px solid var(--orange-predicta);
  margin-right: inherit !important;
  width: 310px;
  &:hover {
    color: var(--orange-predicta);
    border: 1px solid var(--orange-predicta);
    background: #ffffff;
  }
}

.myanswers-header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 16px;
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
}
.question-text {
  font-weight: 600;
  margin-bottom: 12px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  color: #161616;
}
.ansewer-item-container {
  margin-bottom: 12px;
  width: 100%;
}
.ansewer-item-container .answer-item {
  font-weight: 500;
  margin-bottom: 12px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  color: #5c5c5c;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fe;
  border-radius: 8px;
  transition: all ease 0.3s;
  height: 48px;
  cursor: pointer;
  &:hover {
    background: #547894;
    color: white;
  }
}

.note-tag {
  font-weight: "bold";
  color: var(--orange-predicta);
}
// NOTIFICATION
.notifications-wrapper .notifications-tr {
  width: calc(100% - 240px) !important;
}
@media (max-width: 1199.98px) {
  .notifications-wrapper .notifications-tr {
    width: calc(100% - 44px) !important;
  }
}
@media (max-width: 768px) {
  .notifications-wrapper .notifications-tr {
    width: 100% !important;
  }
}
.notif-msg {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #5c5c5c;
  margin-bottom: 0px;
}
.notif-container-success {
  background: #f6ffed;
  border: 2px solid #b7eb8f;
  box-sizing: border-box;
  padding: 20px 13px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 4px;
  position: absolute;
  right: 0px;
  .notif-status {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
  .notif-status > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #4eaf1e;
    margin-bottom: 0px;
  }
}
.notif-container-failed {
  background: #fff1f0;
  border: 2px solid #ff4d4f;
  box-sizing: border-box;
  padding: 16px 20px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-self: center;
  column-gap: 4px;
  position: absolute;
  right: 0px;
  .notif-status {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
  .notif-status > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ff4d4f;
    margin-bottom: 0px;
  }
}
@media (max-width: 520px) {
  .notif-container-success,
  .notif-container-failed {
    row-gap: 4px;
    padding: 16px 8px;
  }
  .notif-msg {
    font-weight: 400;
    font-size: 12px;
  }
}
.invitation-exist-msg {
  color: $form-feedback-invalid-color;
  font-size: 0.9rem;
  font-weight: 600;
}

.tooltip-menu-content {
  background-color: #022282;
}
.tooltip-menu {
  top: 500px !important;
}

.interventional-needs-text {
  text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000,
    1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}
.email-input {
  border-color: #000;
  &::placeholder {
    color: #000;
  }
}

.header-title-invitations {
  color: #022282;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  padding-top: 1.5rem;
}

// Alcohol Consumption
.alcohol-risk-result {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.alcohol-risk-container {
  width: 86%;
  height: 115px;
  background-color: #ffffff;
  box-shadow: 0px 0px 24px #f5f5f5;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 6%;
  padding: 0px 2%;
  margin: auto;
}
.alcohol-risk-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
  margin-bottom: 0px !important;
}

.daily-dose-alcohol-wrapper {
  box-shadow: 0px 0px 24px #f5f5f5;
  background: #fff;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 20px;
}
.daily-dose-alcohol-wrapper h1 {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #161616;
  margin-bottom: 12px;
  text-align: center;
}
.daily-dose-alcohol-wrapper h3 {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #767676;
  text-align: center;
}

.doses-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.day-container {
  background: #fff;
  border-radius: 24px;
  box-shadow: 0px 0px rgba(42, 42, 42, 0.06);
  width: 130px;
  padding: 18px 0px;
}
.day-container p {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #022282;
  text-align: center;
  margin: 0px;
}
.dose-container {
  background: #fff;
  border-radius: 24px;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  width: 141px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 5px 0px;
  gap: 10px;
  align-self: normal;
  flex-grow: 1;
}
.dose-container h6 {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #022282;
  margin: 0px;
  text-align: center;
}
.dose-container p {
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #022282;
  margin: 0px;
  text-align: center;
}
.dose-container-divider {
  border-bottom: 1px solid #cfcfcf;
  width: 98px;
}
.dose-input {
  width: 90%;
  border: none;
  text-align: center;
}

.alcohol-kinds-wrapper {
  margin: 20px 0px;
  background: #fff;
  box-shadow: 0px 0px 24px #f5f5f5;
  padding: 30px 0px;
  border-radius: 24px;
}

.reminder {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #161616;
  margin-bottom: 30px;
  display: inline-block;
}
.alcohol-kinds-wrapper span {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #161616;
}
.alcohol-kinds-wrapper .def {
  display: none;
}
@media (max-width: 992px) {
  .alcohol-kinds-wrapper span {
    display: block !important;
  }
  .reminder {
    margin-bottom: 13px;
  }
  .alcohol-kinds-wrapper .def {
    text-align: center;
    display: inline-flex;
    color: var(--orange-predicta);
    margin-top: 0px;
    gap: 10px;
    font-family: Poppins;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }
}
.alcohol-kinds-container {
  display: flex;
  align-items: baseline;
  gap: 22px;
  overflow: auto;
  padding: 0px 10% 20px;
  justify-content: inherit !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.alcohol-kinds-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.alcohol-kinds-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.alcohol-kinds {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.alcohol-kinds p {
  width: 70px;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #161616;
  margin-bottom: 0px;
}
.alcohol-recommandation-wrapper {
  display: flex;
  padding: 16px;
  border: 3px solid #022282;
  border-radius: 16px;
  max-width: 520px;
  align-items: center;
  gap: 20px;
  margin: 30px auto 0px;
  background: #fff;
}
.alcohol-recommandation-infos h1 {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #022282;
  margin-bottom: 5px;
}
.alcohol-recommandation-infos p {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #161616;
  margin-bottom: 0px;
}
.alcohol-recommandation-wrapper-mobile {
  display: none;
}
@media (max-width: 525px) {
  .alcohol-recommandation-wrapper {
    display: none;
  }
  .alcohol-recommandation-wrapper-mobile {
    display: flex;
    padding: 16px 16px 4px 16px;
    border: 3px solid #022282;
    border-radius: 16px;
    max-width: 520px;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    margin: 30px auto 0px;
  }
  .alcohol-recommandation-wrapper-mobile-header {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 10px;
  }
  .alcohol-recommandation-wrapper-mobile-header > svg {
    width: 24px;
    height: 24px;
  }
  .alcohol-recommandation-wrapper-mobile-header > h1 {
    font-family: Poppins;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #022282;
    margin-bottom: 0px;
  }
  .alcohol-recommandation-infos p {
    font-family: Poppins;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #161616;
    margin-bottom: 12px;
  }
}
.evolution-page-description {
  color: #6B7575;
  padding-left: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 26px;
}
.evolution-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #161616;
  margin-top: 15px;
  margin-bottom: 0px;
}
@media (max-width: 768px) {
  .evolution-name {
    font-size: 18px;
    line-height: 27px;
    margin-top: 9px;
  }
}
.evolution-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2.4rem 1rem !important;
}
// Evolution Item Page
.evolution-item-container {
  max-width: 700px;
  border-radius: 24px;
  background: #fff;
  padding: 20px 25px;
  position: relative;
  margin-top: 23px;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
}
.evolution-item-subtitle-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 12px;
  margin-bottom: 26px;
}
.evolution-item-subtitle {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #022282;
}
.evolution-item-content-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 60vh;
  padding-right: 20px;
}
.evolution-item-content-container::-webkit-scrollbar {
  width: 10px;
}
.evolution-item-content-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.evolution-item-content-container::-webkit-scrollbar-thumb {
  background: #022282;
  border-radius: 10px;
}
.evolution-item-paragraphe {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6B7575;
  text-align: justify;
  margin-bottom: 12px;
}
.evolution-item-image {
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
}
// Evolution Item Text
.evolution-question-container {
  margin-top: 30px;
}
.evolution-buttons-container {
  display: flex;
  gap: 20px;
  margin-bottom: 12px;
  justify-content: center;
}
.evolution-yes-button {
  color: #fff !important;
  background-color: #022282 !important;
  border-radius: 8px !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  max-width: 287px;
  padding: 8px !important;
  width: -webkit-fill-available;
  width: -moz-available;         
  width: fill-available;
}
.evolution-no-button {
  color: #fff !important;
  background-color: #ff6600 !important;
  border-radius: 8px !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  max-width: 287px;
  padding: 8px !important;
  width: -webkit-fill-available;
  width: -moz-available;         
  width: fill-available;
}
.evolution-next-button {
  position: absolute !important;
  bottom: -110px !important;
  color: #fff !important;
  background-color: #022282 !important;
}
// Other Evolution Card
.evolution-other-paragraphe {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #6B7575;
  margin-bottom: 26px;
}
.other-evolution-infos {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.other-evolution-content {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 260px;
  padding-right: 10px;
}
.other-evolution-content::-webkit-scrollbar {
  width: 6px;
}
.other-evolution-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 6px;
}
.other-evolution-content::-webkit-scrollbar-thumb {
  background: #022282;
  border-radius: 6px;
}
.other-evolution-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 26px;
}
@media (max-width: 768px) {
  .other-evolution-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: 10px;
  }
  .other-evolution-image {
    width: 55px;
  }
  .other-evolution-content {
    margin-top: 26px;
  }
}
@media (max-width: 525px) {
  .other-evolution-image {
    width: 30px;
  }
}
.other-evolution-name {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #022282;
  margin: 16px 0px;
}
.other-evolution-description {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #8a8a8a;
  text-align: justify;
}

.delete-contact-item {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  // color: rgba(13, 91, 227, 0.5);
  color: #0a48b3;
}
iframe {
  pointer-events: none;
}
// Removing the clear and reveal password icons from Internet Explorer
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

// Nutrition Block and RPS Questionnaire  (Exit Page)
.nutrition-block-container,
.rps-block-container {
  border-radius: 24px;
  box-shadow: 0px 0px rgba(42, 42, 42, 0.06);
  background: #fff;
  padding: 20px 16px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.nutrition-block-header,
.rps-block-header {
  display: flex;
  gap: 10px;
  align-items: center;
}
.nutrition-block-header > h3,
.rps-block-header > h3 {
  color: #022282;
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 0px;
}
.nutrition-block-container > p {
  color: #161616;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0px;
  width: 100%;
}
.nutrition-block-container > a,
.rps-block-container > a {
  font-family: Poppins;
  background: #022282 !important;
  border-color: #022282 !important;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  font-weight: 500;
  border-radius: 8px;
  width: 100%;
}

.rps-block-main > p {
  color: #161616;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: justify;
}

.exit-page-timer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
}
.exit-page-timer > p {
  color: var(--orange-predicta);
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin: 0px;
}
// .pie-rps-legend {
//   margin-left: -95px;
// }
.pie-rps-legend li {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  width: 175px;
}
.pie-rps-legend .first::before {
  content: "\2022";
  color: #ff6600;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.pie-rps-legend .second::before {
  content: "\2022";
  color: var(--green-predicta);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.pie-rps-legend .third::before {
  content: "\2022";
  color: #022282;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.karasek-model-container {
  position: relative;
}
.faible-tension {
  color: var(--green-predicta);
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  position: absolute;
  top: 12px;
  left: 80px;
}
.activité {
  color: var(--green-predicta);
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  position: absolute;
  top: 12px;
  right: 35px;
}
.forte-tension {
  color: #fc4106;
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  position: absolute;
  bottom: 27px;
  right: 25px;
}
.passivité {
  color: var(--yellow-predicta);
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  position: absolute;
  bottom: 27px;
  left: 80px;
}
.karasek-model-presentation {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.karasek-model-presentation h3 {
  color: #161616;
  font-family: Poppins;
  font-size: 22px;
  line-height: 33px;
  font-weight: 700;
  width: 140px;
  margin: 60px 0px 260px 20px;
}
.karasek-legend li {
  font-family: Poppins;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.karasek-legend .femme {
  color: #ff6600;
}
.karasek-legend .femme::before {
  content: "\2022";
  color: #ff6600;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.karasek-legend .homme {
  color: #022282;
}
.karasek-legend .homme::before {
  content: "\2022";
  color: #022282;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.uncompleted-ques-title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
  margin-bottom: 15px;
}
.principal-ques-card {
  align-items: center;
  padding: 43px 65px;
  margin-bottom: 0px !important;
}
.principal-ques-card h3 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #022282;
  margin-bottom: 40px;
}
.principal-ques-card-description {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #161616;
  margin-bottom: 25px;
}
.col-company-ques-card {
  margin-bottom: 30px;
}
.company-ques-card {
  align-items: center;
  padding: 16px 20px;
  margin-bottom: 0px !important;
}
.company-ques-card h3 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #022282;
  text-align: center;
  max-width: 168px;
}
.company-ques-card-button {
  font-size: 11px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  padding: 6px 7px !important;
  margin-top: 20px !important;
}
@media (max-width: 1234px) {
  .company-ques-card-button {
    font-size: 10px !important;
  }
}
.container-page-title {
  display: flex;
  align-items: flex-start;
  gap: 1em;
  margin: 20px 10px 20px;
}
.container-page-title > svg {
  min-width: 40px !important;
  min-height: 40px !important;
}
.page-title,
.arrow-page-title {
  font-family: Poppins;
  font-weight: 500;
  font-size: 32px;
  line-height: 41px;
  color: #022282;
  margin: 0px;
}
.completed-questionnaire-title {
  display: flex;
  align-items: center;
}
.completed-questionnaire-title > img {
  width: 160px;
}
.completed-questionnaire-title > h1 {
  font-family: Poppins;
  font-size: 28px;
  color: #161616;
  line-height: 42px;
  font-weight: 500;
  width: 200px;
}
@media (max-width: 992px) {
  .completed-questionnaire-title {
    flex-direction: column;
  }
  .completed-questionnaire-title > h1 {
    width: auto;
    margin-bottom: 12px;
  }
}
.ques-finished-h1 {
  font-family: DM Sans;
  font-size: 40px;
  color: #161616;
  text-align: center;
  line-height: 52px;
  font-weight: 500;
  margin-top: 20px;
  max-width: 280px;
}
.ques-finished-p {
  font-family: DM Sans;
  color: #575757;
  margin: 0px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
}
.ques-finished-button {
  margin: 10px 0px;
}
.ques-finished-button > a {
  font-family: Poppins;
  background: var(--orange-predicta) !important;
  border-color: var(--orange-predicta) !important;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  font-weight: 500;
  border-radius: 8px;
  width: 269px;
}
@media (max-width: 768px) {
  .title,
  .not-to-neglect-title,
  .not-to-exceed-title {
    text-align: center;
  }
}
@media (max-width: 525px) {
  .nutrition-modal-pragraphe {
    font-size: 24px;
  }
  .food-quality-container-msg,
  .profil-details {
    width: 100%;
  }
  .food-quality-container,
  .alcohol-risk-container {
    width: 100%;
    flex-direction: column;
  }
  .food-quality-container svg,
  .alcohol-risk-container svg {
    width: 100%;
  }

  .header-body .TitlePage,
  .card-body .titlePage {
    font-size: 32px;
    line-height: 130%;
  }
  .alcohol-kinds-wrapper span {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }

  .alcohol-kinds-container {
    overflow: auto;
    padding: 0px 10% 20px;
    justify-content: inherit !important;
  }
  .dose-container:last-child {
    width: 100%;
  }
  .daily-dose-alcohol-wrapper button {
    width: 100% !important;
  }
  .alcohol-recommandation-wrapper {
    width: 100% !important;
    flex-direction: column;
    padding: 15px 5px 15px 5px;
  }
}

.answer-ques-buttons-container {
  display: flex;
  flex-direction: column;
  margin: 12px 0px 36px;
  gap: 15px;
}
// Desktop NavBar
.web-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(254, 254, 255, 1);
  padding: 9px 21px;
  max-width: none;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
}
.web-navbar-indication-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.web-navbar-indication-container > svg {
  filter: grayscale(1);
  width: 20px;
}
.web-navbar-page:hover .web-navbar-indication-container > svg {
  filter: unset !important;
}
.web-navbar-page {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5c5c5c;
  cursor: pointer;
}
.web-navbar-section {
  margin-bottom: 0px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5c5c5c;
  cursor: pointer;
}
.web-navbar-indication-container > span {
  margin-bottom: 0px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5c5c5c;
}
.web-navbar-page:hover,
.web-navbar-section:hover {
  color: rgba(0, 52, 186, 1);
}
.web-navbar-page .web-navbar-icon svg {
  filter: grayscale(1);
  width: 20px;
}
.web-navbar-page:hover .web-navbar-icon svg {
  filter: unset !important;
}
.right-web-navbar {
  display: flex;
  align-items: center;
  gap: 25px;
}
.web-notifications-wrapper,
.web-language-wrapper {
  display: flex;
  align-items: center;
  width: 375px;
}
.web-notifications-wrapper > Button,
.web-language-wrapper > Button {
  background-color: rgba(248, 248, 248, 1);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  margin: 0px !important;
  border-radius: 0px !important;
  color: #5c5c5c;
}
.web-notifications-wrapper > Button > svg {
  margin-right: 0px !important;
}
.web-notifications-wrapper > Button:hover,
.web-language-wrapper > Button:hover {
  border: none !important;
  transform: none !important;
}
.web-notifications-wrapper > Button:focus,
.web-language-wrapper > Button:focus {
  color: #022282 !important;
  background-color: #fff !important;
}
// .web-notifications-wrapper > Button:focus svg {
//   filter: unset;
// }
.web-read-notifications-wrapper {
  background: #f8f8f8;
  padding: 16px 20px 16px 20px;
  border-top: 1px solid var(--lightgray-predicta);
  border-bottom: 1px solid var(--lightgray-predicta);
  border-left: 1px solid var(--lightgray-predicta);
  width: 375px;
}
.web-read-notifications-header {
  display: flex;
  justify-content: space-between;
}
.web-read-notifications-header > h5 {
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 12px !important;
  color: #161616;
}
.web-read-notifications-wrapper > p {
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 0px !important;
  color: #5c5c5c;
}
.web-read-notifications-wrapper > button {
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-top: 12px !important;
  color: #0034ba;
  border: none !important;
  padding: 0px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}

// Responsive NavBar
.mobile-navbar,
.side-bar-mobile-wrapper {
  display: none;
}
@media (max-width: 768px) {
  .sidenav.navbar.navbar-vertical.fixed-left.navbar-expand-xs.navbar-light.bg-white.navbar-expand-md {
    display: none;
  }
  // .main-content {
  //   margin-left: 0px !important;
  // }
  .sidenav.fixed-left + .main-content {
    margin-left: 0px !important;
  }
  .web-navbar {
    display: none;
  }
  .web-notifications-wrapper,
  .web-language-wrapper {
    display: none;
  }
  .mobile-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    background: #fefeff;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
  }
  .left-mobile-navbar {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .right-mobile-navbar {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  .mobile-notifications-wrapper,
  .language-wrapper {
    display: flex;
    align-items: center;
  }
  .mobile-notifications-wrapper > Button,
  .language-wrapper > Button {
    background: #f8f8f8;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    color: #5c5c5c;
    padding: 9.5px 0px !important;
  }
  .mobile-notifications-wrapper > Button > svg {
    filter: grayscale(1);
  }
  .mobile-notifications-wrapper > Button:hover,
  .language-wrapper > Button:hover {
    border: none !important;
    transform: translateY(1px) !important;
  }
  .mobile-notifications-wrapper > Button:focus,
  .language-wrapper > Button:focus {
    color: #022282 !important;
    background-color: #fff !important;
  }
  .mobile-notifications-wrapper > Button:focus svg {
    filter: unset;
  }
  .side-bar-mobile-wrapper {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;
  }
  .side-bar-mobile-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .side-bar-mobile {
    z-index: 1050;
    background: #fff;
    width: 65%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    position: fixed;
    top: 0px;
    min-width: 280px;
  }
  .side-bar-mobile-header {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 12px 15px 28px;
    margin: 5px 0px;
    width: 100%;
  }
  .nav-link-text {
    display: block !important;
    font-family: Poppins;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
  .side-bar-mobile .navbar-nav .nav-item .nav-link {
    display: flex;
    align-items: center;
    white-space: nowrap;
    column-gap: 10px;
    padding: 8px 0px 8px 24px !important;
  }
  .side-bar-mobile .navbar-nav .nav-item .nav-link.active {
    display: flex;
    align-items: center;
    white-space: nowrap;
    column-gap: 10px;
    padding: 8px 0px 8px 24px !important;
    background: #fff8f5 !important;
    border-radius: 8px !important;
  }
  .side-bar-mobile .navbar-nav .nav-item .nav-link.active.nutrition,
  .side-bar-mobile .navbar-nav .nav-item .nav-link.active.media {
    display: flex;
    align-items: center;
    white-space: nowrap;
    column-gap: 10px;
    padding: 8px 0px 8px 24px !important;
    background: #fff !important;
    border-radius: 8px !important;
  }
  .nav-item {
    margin-top: 2px;
  }
  .nav-link:hover {
    color: #0034ba !important;
  }
  .read-notifications-wrapper {
    background: #f8f8f8;
    padding: 16px 20px 16px 20px;
    border-top: 1px solid var(--lightgray-predicta);
    border-bottom: 1px solid var(--lightgray-predicta);
  }
  .read-notifications-header {
    display: flex;
    justify-content: space-between;
  }
  .read-notifications-header > h5 {
    font-family: Poppins;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 12px !important;
    color: #161616;
  }
  .read-notifications-wrapper > p {
    font-family: Poppins;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 0px !important;
    color: #5c5c5c;
  }
  .read-notifications-wrapper > button {
    font-family: Poppins;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin-top: 12px !important;
    color: #0034ba;
    border: none !important;
    padding: 0px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: 0px !important;
  }
}
// Messagerie
.messages-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.welcome-messages-title {
  text-align: center;
  margin: auto;
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #022282;
}
.new-message-web {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #ffffff !important;
  background: #0034ba !important;
  box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.06) !important;
  border-radius: 8px !important;
  padding: 12px 48px !important;
  height: fit-content;
}
.new-message-mobile {
  display: none;
}
.answer-new-message {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #ffffff !important;
  background: #0034ba !important;
  box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.06) !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  float: right;
}
.messages-description {
  display: block !important;
  text-align: center;
  padding-top: 12px;
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #6B7575;
}
.messages-description details {
  margin-bottom: 20px;
}
.messages-description summary {
  color: #0034ba !important;
  text-align: center;
  list-style: none;
  display: flex;
  justify-content: center;
}
.summary-infos {
  display: flex;
  align-items: center;
  gap: 10px;
}
.messages-description summary p {
  margin-bottom: 0px;
}
.messages-description p {
  margin-bottom: 12px;
}
.messages-privacy {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--orange-predicta);
  display: block;
  text-align: center;
  margin: 0px auto;
}
.message-card-container {
  justify-content: center !important;
  padding-bottom: 30px !important;
  row-gap: 15px;
}
.message-card {
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  border-radius: 16px !important;
}
.message-card-body-web {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  row-gap: 16px;
}
.message-card-body-mobile {
  display: none;
}
.message-name {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #161616;
  margin-bottom: 12px;
  text-align: center;
}
.message-date {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #5c5c5c;
  margin-bottom: 0px;
  text-align: center;
}

@media (max-width: 768px) {
  .new-message-web {
    display: none;
  }
  .new-message-mobile {
    display: block;
    font-family: Poppins;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #ffffff !important;
    background: #0034ba !important;
    box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.06) !important;
    border-radius: 8px !important;
    padding: 12px 20px !important;
    margin: 12px auto 0px;
    margin-right: 0px;
  }
  .messages-description {
    text-align: start;
  }
}
@media (max-width: 576px) {
  .message-card-body-web {
    display: none;
  }
  .message-card-body-mobile {
    display: flex;
    align-items: center;
    padding: 16px;
    row-gap: 14px;
    justify-content: space-between;
  }
  .message-name {
    margin-bottom: 0px;
  }
}
@media (max-width: 600px) {
  .answer-new-message {
    width: 100%;
    float: none;
  }
}

.cancel-button {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #5c5c5c;
  background: #f0f0f0;
  border-radius: 8px !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  padding: 12px !important;
  width: 140px;
  margin: 0px !important;
}
.cancel-button:hover {
  box-shadow: none !important;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #5c5c5c;
  transform: none !important;
}
.send-message {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #fff;
  border-radius: 8px !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  padding: 12px !important;
  width: 140px;
}
// .send-message:hover {
//   box-shadow: none !important;
//   background-color: #0034ba;
//   border-color: #0034ba;
//   color: #fff;
//   transform: none !important;
// }
.questionnaires-list-wrapper {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  column-gap: 20px;
}
.questionnaires-list-wrapper > h5 {
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: #161616;
}
.filter-list {
  column-gap: 8px;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  border-bottom: unset;
  padding-bottom: 20px;
  flex-grow: 1;
}
.filter-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.filter-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
// .filter-list::-webkit-scrollbar {
//   width: 10px;
// }
// .filter-list::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }
// .filter-list::-webkit-scrollbar-thumb {
//   background: #022282;
//   border-radius: 10px;
// }
.filter-list > li {
  border-radius: 24px;
  background: #fff;
  color: #5c5c5c;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  font-size: 14px;
  line-height: 21px;
  font-family: Poppins;
  cursor: pointer;
}
.filter-list > li > a {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 7.5px 16px !important;
}
.filter-list > li .active {
  border-radius: 24px;
  background: #fff !important;
  color: #022282 !important;
  border: 2px solid #022282 !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  cursor: pointer;
}

.recommendation-card-title {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #161616;
}

.recommendation-card-text {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 450px;
}
.recommendation-card-text::-webkit-scrollbar {
  width: 5px;
}
.recommendation-card-text::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(199, 199, 199, 0.2);
  border-radius: 20px;
}
.recommendation-card-text::-webkit-scrollbar-thumb {
  background: var(--lightgray-predicta);
  border-radius: 5px;
}
@media (max-width: 768px) {
  .recommendation-card-text {
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: auto;
  }
  .recommendation-card-title {
    text-align: center;
    margin-bottom: 30px;
  }
  .recommendation-card-content {
    flex-wrap: wrap-reverse;
    row-gap: 30px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 590px;
  }
  .recommendation-card-content::-webkit-scrollbar {
    width: 5px;
  }
  .recommendation-card-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(199, 199, 199, 0.2);
    border-radius: 20px;
  }
  .recommendation-card-content::-webkit-scrollbar-thumb {
    background: var(--lightgray-predicta);
    border-radius: 5px;
  }
  .recommendation-card-image {
    display: flex;
    justify-content: center;
  }
  .recommendation-card-text {
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: fit-content;
  }
}
// UnreachedEvolution
.unanswered-evolution {
  height: fit-content;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  padding: 45px 0px;
}
.unanswered-evolution-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
}
.unanswered-evolution-paragraphe {
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #161616;
}
.unanswered-evolution-indication {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #575757;
}
.unanswered-evolution-button {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fff !important;
  background: #ff3c08 !important;
  padding: 12px 16px !important;
  border-radius: 8px;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
}
@media (max-width: 768px) {
  .unanswered-evolution {
    padding: 0px;
  }
}

// Media
.videos-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.videos-description,
.audios-description,
.articles-description {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #6B7575;
  margin-bottom: 24px;
}
.videos-type {
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: #161616;
  margin-bottom: 0px;
}
.display-videos {
  color: #0034ba;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}
.web-videos-container {
  flex-wrap: nowrap;
  margin-bottom: 40px;
}
.mobile-videos-container {
  display: none;
}
.video-container {
  padding: 0px 10px;
}
.video-card {
  cursor: pointer;
  border-radius: 16px;
  padding: 12px;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  margin: 0px !important;
  row-gap: 10px;
}
.video-card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  gap: 10px;
  padding: 0px !important;
  // height: 140px;
}
.video-title {
  color: #022282;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}
.video-description {
  color: #6B7575;
  text-align: justify;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}
.video-date {
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: var(--orange-predicta);
  margin: 0px;
}
.specific-video-img {
  position: relative;
}
.videos-type-container {
  row-gap: 12px;
  margin-bottom: 40px;
}
.video-type-title {
  color: #022282;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
}
@media (max-width: 768px) {
  .web-videos-container {
    display: none;
  }
  .mobile-videos-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    row-gap: 12px;
  }
  .video-card {
    flex-direction: row-reverse;
    column-gap: 12px;
    align-items: center;
  }
  .video-card img {
    width: 50%;
    object-fit: cover;
    object-position: center center;
  }
  .video-card-body {
    gap: 8px;
  }
  .specific-video-img {
    width: 50%;
  }
  .videos-type-container {
    flex-direction: column;
  }
}
@media (max-width: 380px) {
  .video-card img {
    width: 140px;
    height: 100px;
    object-fit: cover;
    object-position: center center;
  }
  .specific-video-img {
    width: 140px;
    object-fit: cover;
    object-position: center center;
  }
}
.card-body-article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px !important;
  text-align: start;
}
.video-duration {
  color: var(--orange-predicta);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  margin: 0px;
}
.card-audio {
  min-height: 1px !important;
  margin: 0px !important;
}
.card-body-audio {
  padding: 16px !important;
  height: 65px !important;
}
.go-to-our-blog {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.go-to-our-blog h1 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: #161616;
}
.go-to-our-blog button {
  width: 340px !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: white !important;
  background-color: #0034ba !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  border-radius: 8px !important;
  padding: 12px 16px;
}

// NUTRITION FIRST NOTE
.nutrition-note-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  margin-bottom: 12px;
}
.nutrition-note-title h1 {
  margin-bottom: 0px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #161616;
}
.nutrition-note-description p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #6B7575;
  margin-bottom: 12px !important;
}
.nutrition-note-button {
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #fff !important;
  background-color: #0034ba !important;
  padding: 12px 8px !important;
  width: 100%;
  border-radius: 8px !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
}
//  ToolTip sidebar
.sidebar-tooltip-content {
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #fff !important;
  background-color: #5c5c5c !important;
  padding: 8px !important;
  border-radius: 4px !important;
}
.side-bar-tooltip .tooltip {
  left: 68px !important;
}
.submenu-tooltip-content {
  background: #f8f8f8 !important;
  opacity: 1;
  padding: 8px !important;
  border-radius: 2px !important;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.12);
}
.submenu-text {
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #5c5c5c !important;
  text-align: start;
}
.submenu-text:hover {
  color: #0034ba !important;
}
.active-submenu {
  background-color: rgba(255, 81, 26, 0.05);
  color: #0034ba !important;
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-nav {
    gap: 10px;
  }
}

// CAROUSEL
.carousel-slider .control-arrow {
  background: rgba(22, 22, 22, 0.5) !important;
  height: 100% !important;
}
.carousel-slider .control-arrow.control-next {
  border-radius: 0px 16px 16px 0px !important;
}
.carousel-slider .control-arrow.control-prev {
  border-radius: 16px 0px 0px 16px !important;
}

// MODAL
@media (max-width: 768px) {
  .modal.fade .modal-dialog {
    transform: translate(0, 50px) !important;
    margin: 1rem !important;
    padding-bottom: 20px;
  }
}
// HEALTH CARD MOBILE
.legend-title {
  margin-top: -50px;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  font-family: "Poppins";
  color: #161616;
  margin-bottom: 15px;
}
.legend-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}
.legend-fname,
.legend-lname {
  text-align: center;
  font-size: 9px;
  font-weight: 600;
  line-height: 11px;
  font-family: "Poppins";
  color: "#5C5C5C";
  margin-bottom: 0px;
}
.success-msg-title {
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  font-family: "Poppins";
  color: #161616;
  max-width: 727px;
  margin: 26px auto;
}
.success-msg-paragraphe {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Poppins";
  color: var(--lightgray-predicta);
  max-width: 456px;
  margin: auto;
}
.questionnaire-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px !important;
}
// FIRST PAGE QUESTIONNAIRE
.questionnaire-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  width: 100%;
}
.questionnaire-page-header {
  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}
.questionnaire-page-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: "Poppins";
  color: #5c5c5c;
  margin-bottom: 0px;
}
.questionnaire-exit-icon {
  position: absolute;
  top: 0px;
  right: 6px;
  cursor: pointer;
}
.questionnaire-rps-page-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.questionnaire-rps-exit-icon {
  cursor: pointer;
}
.questionnaire-question {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  font-family: "Poppins";
  color: #161616;
  margin-bottom: 0px;
  text-align: center;
}
.first-page-buttons-wrapper {
  display: flex;
  width: 100%;
  column-gap: 20px;
}
.first-page-button {
  width: 50%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Poppins";
  color: #ffffff !important;
  background: #547893 !important;
  border-radius: 8px !important;
  padding: 12px !important;
  margin: 0px !important;
}
.second-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  width: 100%;
  text-align: center;
}
.human-body-indication {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Poppins";
  margin-bottom: 0px;
  color: #5c5c5c;
}
.questionnaire-previous-button,
.questionnaire-next-button {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Poppins";
  margin-bottom: 0px;
  cursor: pointer;
}
.questionnaire-previous-button {
  color: #161616 !important;
  background-color: transparent;
  border: none;
}
.questionnaire-next-button {
  color: #0034ba !important;
  background-color: transparent;
  border: none;
}
.pain-area-container {
  text-align: center;
}
.pain-area-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: "Poppins";
  margin-bottom: 0px;
  color: rgba(22, 22, 22, 1);
}
.pain-area {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  font-family: "Poppins";
  margin-bottom: 0px;
  color: rgba(255, 81, 26, 1);
}
.no-area-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: "Poppins";
  margin-bottom: 0px;
  color: #5c5c5c;
}
.progress-container {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 20px;
}
.progress-line {
  margin-bottom: 0px;
  height: 16px !important;
  border-radius: 100px !important;
}
.progress-line .progress-bar {
  background-color: var(--green-predicta) !important;
  border-radius: 100px !important;
}
.progress-value {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Poppins";
  color: #022282;
}
.finish-questionnaire {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  font-family: "Poppins" !important;
  margin-bottom: 0px !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  padding: 12px !important;
  height: 48px !important;
  box-shadow: none !important;
}
@media (max-width: 490px) {
  .finish-questionnaire {
    font-size: 14px !important;
    line-height: 21px !important;
    width: 135px !important;
    height: auto !important;
  }
}
@media (max-width: 768px) {
  .question-dispaly-container {
    padding: 0px !important;
  }
}
// Second page
.human-body-container {
  flex-wrap: nowrap;
}
@media (max-width: 768px) {
  .human-body-container {
    height: 380px;
  }
  .human-body-front,
  .human-body-back {
    transform: scale(1.5);
    translate: -125px 0px;
  }
}
@media (max-width: 520px) {
  .human-body-front,
  .human-body-back {
    transform: scale(1.6);
    translate: -150px 0px;
  }
}
@media (max-width: 490px) {
  .human-body-front,
  .human-body-back {
    transform: scale(1.7);
    translate: -180px 0px;
  }
}
@media (max-width: 460px) {
  .human-body-front,
  .human-body-back {
    transform: scale(1.8);
    translate: -205px 0px;
  }
}
@media (max-width: 460px) {
  .human-body-front,
  .human-body-back {
    transform: scale(1.8);
    translate: -205px 0px;
  }
}
@media (max-width: 440px) {
  .human-body-front,
  .human-body-back {
    transform: scale(1.9);
    translate: -225px 0px;
  }
}
@media (max-width: 420px) {
  .human-body-front,
  .human-body-back {
    transform: scale(2);
    translate: -245px 0px;
  }
}
@media (max-width: 400px) {
  .human-body-front,
  .human-body-back {
    transform: scale(2.1);
    translate: -275px 0px;
  }
}
@media (max-width: 380px) {
  .human-body-front,
  .human-body-back {
    transform: scale(2.2);
    translate: -305px 0px;
  }
}
.page-item {
  cursor: pointer !important;
}
.messagerie-text-confirmation {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Poppins";
  color: rgba(0, 52, 186, 1);
  margin-bottom: 20px;
  margin-top: 10px;
}
.web-nutritional-page {
  display: block;
}
.mobile-nutritional-page {
  display: none;
}
@media (max-width: 768px) {
  .web-nutritional-page {
    display: none;
  }
  .mobile-nutritional-page {
    display: block;
  }
}
.nutritional-table-description {
  color: #767676;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px !important;
}
.nutritional-table-description span {
  color: rgba(0, 52, 186, 1);
}
.columns-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.column-container {
  display: flex;
  column-gap: 8px;
}
.column-infos {
  border-radius: 8px;
  background: #f1f8ff;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex: 0.72 0;
  height: 52px;
  cursor: pointer;
}
.column-infos h6 {
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
  color: rgba(0, 52, 186, 1);
}
.column-infos p {
  color: #5c5c5c;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}
.column-foods {
  border-radius: 8px;
  background: #fff;
  display: flex;
  padding: 6px 0px;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
  height: 52px;
  // flex-wrap: wrap;
  overflow: hidden;
  p {
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 376px) {
  .column-foods {
    max-width: 167px;
  }
}
.nutritional-buttons {
  display: flex;
  margin-top: 24px;
  width: 100%;
}
.cancel-nutrition-button {
  border-radius: 8px !important;
  background: #f0f0f0 !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  padding: 12px !important;
  margin: 0px !important;
  color: #5c5c5c;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 48px !important;
  width: 199px !important;
}
.save-nutrition-button {
  border-radius: 8px !important;
  background: #0034ba !important;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06) !important;
  padding: 12px !important;
  margin: 0px !important;
  color: #fff !important;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 48px !important;
  width: 199px !important;
}
@media (max-width: 1020px) {
  .nutritional-buttons {
    gap: 20px;
  }
  .cancel-nutrition-button,
  .save-nutrition-button {
    // flex: 1 1;
    // width: auto !important;
    width: 140px !important;
  }
}
@media (max-width: 768px) {
  .nutritional-buttons {
    margin-top: 14px;
  }
}
.nutrition-row {
  margin: 0px !important;
}
@media (max-width: 768px) {
  .cancel-nutrition-button,
  .save-nutrition-button {
    width: 220px !important;
  }
  .nutrition-row {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
}
@media (max-width: 520px) {
  .cancel-nutrition-button,
  .save-nutrition-button {
    width: 145px !important;
  }
}
.stepper-nutritional-wrapper {
  display: flex;
  gap: 12px !important;
  justify-content: center;
}
.step-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--lightgray-predicta);
  text-align: center;
}
.step-circle span {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.step-circle-active {
  background: var(--orange-predicta);
}
.step-circle-completed {
  background: #767676;
}
@media (max-width: 768px) {
  .stepper-nutritional-wrapper {
    gap: 8px !important;
  }
  .step-circle {
    width: 12px;
    height: 12px;
    background: #fff;
    border: 2px solid #767676;
  }
  .step-circle-active {
    background: var(--orange-predicta);
    border: none;
  }
  .step-circle-completed {
    background: #767676;
    border: none;
  }
  .step-circle span {
    display: none;
  }
  .steps-title {
    font-size: 18px;
    font-weight: 600;
    margin: 12px 0px !important;
  }
}
.confirm-message {
  color: #0034ba;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
  padding-left: 16px;
}
.send-msg-mobile-button {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0px 0px !important;
  box-shadow: 0px 0px 24px 0px rgba(42, 42, 42, 0.06) !important;
}
.msg-mobile-container {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  margin-top: 17px;
}
.msg-mobile-container .form-group {
  flex: 1;
}
.cancel-send-msg-icon {
  width: 24px;
  height: 24px;
  background-color: var(--lightgray-predicta);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.cancel-send-msg-icon p {
  margin: 0px;
  color: #fff;
  font-size: 12px;
}
@media (min-width: 768px) {
  .cancel-send-msg-icon:hover {
    background-color: #5c5c5c;
  }
}
.cancel-send-tooltip-content {
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #fff !important;
  background-color: #5c5c5c !important;
  padding: 8px !important;
  border-radius: 4px !important;
}
.cancel-send-tooltip .tooltip {
  left: 10px !important;
}
.cancel-send-tooltip-arrow {
  display: none !important;
}
.chat-card-container {
  padding: 16px;
}
@media (max-width: 768px) {
  .chat-card-container {
    margin-bottom: 130px !important;
  }
}
